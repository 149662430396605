<template>
<div class="draw_board">
  <div class="board_wapper" v-if="data.length > 0">
    <el-row :gutter="10">
      <!-- <el-col :span="span">
        <div class="new_board" @click="boardVisible = true">
          <div class="inner_box">
            <div class="inner">
              <i class="el-icon-circle-plus-outline"></i>
              <span>创建画板</span>
            </div>
          </div>
        </div>
      </el-col> -->
      <el-col :span="span" v-for="(board, index) in data" :key="index">
        <div class="board" @click="boardDetails(board.id)">
          <div class="bodyer" ref="board">
            <div class="cover">
              <el-image :src="board.preview[0] ? board.preview[0].url : ''" fit="cover"></el-image>
            </div>
            <div class="small_cover">
              <el-row :gutter="5" type="flex" justify="space-between">
                <el-col :span="8">
                  <div class="s_cover">
                    <el-image :src="board.preview[1].url" fit="cover" v-if="board.preview[1]"></el-image>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="s_cover">
                    <el-image :src="board.preview[2].url" fit="cover" v-if="board.preview[2]"></el-image>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="s_cover">
                    <el-image :src="board.preview[3].url" fit="cover" v-if="board.preview[3]"></el-image>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="board_name">
              <h3>{{board.title}}</h3>
              <h4>{{board.like_num}} 喜欢</h4>
            </div>
            <div class="set_cover">
              <div class="btn" @click.stop="setCover(index)">设置封面</div>
            </div>         
          </div>
          <div class="footer">
            <div class="edit" @click.stop="editBoard(index)">编 辑</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="no_board" v-else>找不到任何画板</div>
  <div class="dialog_boxes" v-if="coverVisible || editVisible || boardVisible">
    <div class="cover_dialog" v-if="coverVisible">
      <div class="dialog_title">
        <h2 class="title">设置封面 / {{edit.title}}</h2>
        <i class="el-icon-close" @click.stop="coverVisible = false"></i>
      </div>
      <div class="img_list">
        <el-carousel type="card" indicator-position="none" :autoplay="autoplay" height="300px">
          <el-carousel-item v-for="(item, index) in edit.preview" :key="index">
            <el-image :src="item.url" fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="btns">
        <el-button type="info" plain @click="coverVisible = false">取消</el-button>
        <el-button type="danger">确认保存</el-button>
      </div>
    </div>
    <div class="edit_dialog" v-if="editVisible">
      <div class="dialog_title">
        编辑画板
        <i class="el-icon-close" @click.stop="editVisible = false"></i>
      </div>
      <div class="dialog_form">
        <div class="title">
          <div class="label">标 题</div>
          <el-input label="哈哈哈哈" placeholder="为您的画板取个名字" clearable v-model="edit.title"></el-input>
        </div>
        <div class="desc">
          <div class="label">简 介</div>
          <el-input type="textarea" placeholder="画板简介(最多255字)" clearable :autosize="true" resize="none" maxlength="255" show-word-limit v-model="edit.desc"></el-input>
        </div>
        <div class="album">
          <div class="label">专 辑</div>
          <el-select v-model="album" filterable placeholder="选择专辑">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="classify">
          <div class="label">分 类</div>
          <el-select v-model="catTitle" filterable placeholder="选择分类">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="cover">
          <div class="label">封 面</div>
          <div class="btn" @click.stop="setCover(index)">修 改</div>
        </div>
      </div>
      <div class="dialog_footer">
        <el-button>删除画板</el-button>
        <div class="btns">
          <el-button type="info" plain @click="editVisible = false">取消</el-button>
          <el-button type="danger">确认</el-button>
        </div>
      </div>
    </div>
    <div class="board_dialog" v-if="boardVisible">
      <div class="dialog_title">
        新建画板
        <i class="el-icon-close" @click.stop="boardVisible = false"></i>
      </div>
      <div class="dialog_form">
        <el-input placeholder="画板标题" v-model="boardTitle" clearable></el-input>
        <el-select v-model="boardCat" filterable placeholder="选择分类">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="btns">
        <el-button type="info" plain @click.stop="boardVisible = false">取消</el-button>
        <el-button type="danger">创建画板</el-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    span: {
      type: Number,
      default: 4
    },
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      coverVisible: false,
      autoplay: false,
      editVisible: false,
      edit: {},
      album: '',
      catTitle: '',
      options: [{
          value: '选项1',
          label: 'UI/UX'
        }, {
          value: '选项2',
          label: '摄影'
        }, {
          value: '选项3',
          label: '建筑设计'
        }, {
          value: '选项4',
          label: '人文艺术'
        }, {
          value: '选项5',
          label: '手工/布艺'
      }],
      boardVisible: false,
      boardTitle: '',
      boardCat: '',
    }
  },
  methods: {
    editBoard(index) {
      this.editVisible = true;
      this.edit = JSON.parse(JSON.stringify(this.data[index]));
    },
    setCover(index) {
      this.coverVisible = true;
      this.edit = JSON.parse(JSON.stringify(this.data[index]));
    },
    boardDetails(id) {
      this.$router.push({
        path: '/collectBoard',
        query: { id }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.draw_board {
  user-select: none;
  .board_wapper {
    .new_board {
      position: relative;
      box-sizing: border-box;
      height: 350px;
      padding: 10px;
      background: rgba(255,255,255,.5);
      box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.12);
      border-radius: 3px;
      overflow: hidden;
      transition: transform .15s ease-in-out,box-shadow .15s ease-in-out;
      cursor: pointer;
      &:hover {
        background: #fff;
        box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 16px 32px -4px rgba(0,0,0,.17);
        .inner_box {
          .inner {
            color: #777777;
          }
        }
      }
      .inner_box {
        height: 100%;
        border: 2px dashed #dedede;
        text-align: center;
        color: #ddd;
        position: relative;
        .inner {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          > i {
            display: block;
            font-size: 3em;
          }
        }
      }
    }
    .board {
      box-sizing: border-box;
      position: relative;
      height: 350px;
      padding: 10px;
      margin-bottom: 10px;
      background: rgba(255,255,255,.5);
      box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.12);
      border-radius: 3px;
      overflow: hidden;
      transition: transform .15s ease-in-out,box-shadow .15s ease-in-out;
      cursor: pointer;
      &:hover {
        background: #fff;
        box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 16px 32px -4px rgba(0,0,0,.17);
        transform: translateY(-3px);
        .bodyer {
          .set_cover {
            opacity: 1;
          }
        }
      }
      .bodyer {
        height: 290px;
        margin-bottom: 10px;
        position: relative;
        .cover {
          height: 215px;
          margin-bottom: 5px;
          .el-image {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
          }
        }
        .small_cover {
          height: 70px;
          .s_cover {
            box-sizing: border-box;
            height: 70px;
            overflow: hidden;
            box-shadow: inset 0 0 2px rgba(0,0,0,.2);
            .el-image {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
            }
          }
        }
        .board_name {
          position: absolute;
          left: 10px;
          top: 10px;
          color: #fff;
          > h3 {
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-shadow: 0 1px 3px rgba(0,0,0,.5);
          }
          > h4 {
            margin-top: 3px;
            height: 14px;
            line-height: 14px;
            font-size: 10px;
            font-weight: 400;
            text-shadow: 0 1px 3px rgba(0,0,0,.5);
          }
        }
        .set_cover {
          position: absolute;
          right: 10px;
          bottom: 85px;
          transition: opacity .2s linear;
          opacity: 0;
          .btn {
            padding: 0 9px;
            border-radius: 2px;
            height: 28px;
            line-height: 28px;
            background: #ededed;
            border: 1px solid #ededed;
            cursor: pointer;
            text-decoration: none;
            color: #444;
            white-space: nowrap;
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            text-align: center;
            box-shadow: 0 0 0 transparent;
            transition: background-color .12s ease-in-out;
            &:hover {
              border: 1px solid #d9d9d9;
              background: #d9d9d9;
              color: #444;
              text-decoration: none;
              box-shadow: none;
            }
          }
        }
      }
      .footer {
        height: 30px;
        text-align: center;
        display: flex;
        .edit {
          flex: 2;
          height: 30px;
          line-height: 30px;
          box-sizing: border-box;
          background: #ededed;
          border: 1px solid #d9d9d9;
          &:hover {
            background: #d9d9d9;
            color: #444;
          }
        }
      }
    }
  }
  .no_board {
    width: 100%;
    text-align: center;
    padding: 150px 0;
    color: #999;
    font-size: 1.2em;
  }
  .dialog_boxes {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    z-index: 1999;
    .edit_dialog {
      width: 550px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 2px 5px rgba(0,0,0,.3);
      border-radius: 1px;
      .dialog_title {
        font-size: 16px;
        border-bottom: 1px solid #ededed;
        position: relative;
        padding: 15px 30px;
        box-sizing: border-box;
        color: #222;
        margin-bottom: 20px;
        .el-icon-close {
          float: right;
          font-size: 30px;
          margin-top: -3px;
          color: #777;
          cursor: pointer;
        }
      }
      .dialog_form {
        padding: 0 30px;
        color: #777;
        .title,
        .desc,
        .album,
        .classify {
          padding: 10px 0;
          .label {
            display: inline-block;
            width: 50px;
            margin-right: 10px;
            line-height: 40px;
            vertical-align: top;
          }
          .el-input {
            display: inline-block;
            width: 430px;
          }
          .el-select {
            display: inline-block;
            width: 430px;
          }
          ::v-deep .el-textarea {
            display: inline-block;
            width: 430px;
            .el-textarea__inner {
              min-height: 100px!important;
            }
          }
        }
        .cover {
          padding: 10px 0;
          .label {
            display: inline-block;
            width: 50px;
            margin-right: 10px;
            line-height: 40px;
            vertical-align: top;
          }
          .btn {
            box-sizing: border-box;
            display: inline-block;
            line-height: 40px;
            padding: 0 15px;
            background: #ededed;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            &:hover {
              
              background: #d9d9d9;
            }
          }
        }
      }
      .dialog_footer {
        border-top: 1px solid #ededed;
        margin-top: 20px;
        padding: 20px 30px;
        .btns {
          float: right;
        }
      }
    }
    .cover_dialog {
      width: 760px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 2px 5px rgba(0,0,0,.3);
      border-radius: 1px;
      z-index: 1;
      .dialog_title {
        padding: 20px 30px;
        background: #FAFAFA;
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 20px;
        position: relative;
        .title {
          font-size: 20px;
          color: #999;
          text-shadow: 0 1px #fff;
        }
        .el-icon-close {
          position: absolute;
          right: 30px;
          top: 20px;
          width: 30px;
          font-size: 30px;
        }
      }
      .btns {
        margin-top: 20px;
        border-top: 1px solid #ebebeb;
        padding: 10px 30px;
        text-align: center;
      }
    }
    .board_dialog {
      width: 550px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 2px 5px rgba(0,0,0,.3);
      border-radius: 1px;
      .dialog_title {
        font-size: 16px;
        border-bottom: 1px solid #ededed;
        position: relative;
        padding: 15px 30px;
        box-sizing: border-box;
        color: #222;
        margin-bottom: 20px;
        .el-icon-close {
          float: right;
          font-size: 30px;
          margin-top: -3px;
          color: #777;
          cursor: pointer;
        }
      }
      .dialog_form {
        padding: 0 30px;
        .el-select {
          margin-top: 20px;
          width: 100%;
        }
      }
      .btns {
        margin-top: 20px;
        border-top: 1px solid #ebebeb;
        padding: 10px 30px;
        text-align: center;
      }
    }
  }
}
</style>